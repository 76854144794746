import React, { useRef, useState } from "react";
import "./Contact.scss";
import emailjs from "@emailjs/browser";
import { motion } from "framer-motion";
import { contacts } from "./Data";
import { FaPaperPlane } from "react-icons/fa";
import { CircularProgress } from "@nextui-org/react";
import { emailKey, serviceId, templateId } from "../../config";

const Contact = () => {
  const form = useRef();
  const [done, setDone] = useState(false);
  const [isLoading, SetIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");


  const sendEmail = (e) => {
    e.preventDefault();

    if (!email || !username || !message) {
      setErrorMessage("*Please provide all form details.");
    } else {
      setErrorMessage("");
      SetIsLoading(true);

      emailjs.sendForm(serviceId, templateId, form.current, emailKey).then(
        (result) => {
          SetIsLoading(false);
          setEmail("");
          setUsername("");
          setMessage("");
          setDone(true);
        },
        (error) => {
          SetIsLoading(false);
          setErrorMessage("*Something went wrong. Try again later");
        }
      );
    }
  };

  return (
    <div className="container" id="contact">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ y: [-30, 0], opacity: 1 }}
        className="text-center mb-3"
      >
        <span className="text-yellow-500 uppercase" >get in touch</span>
        <h1 className="text-4xl font-semibold">Contact Us</h1>
      </motion.div>
      <div className="contact_form">
        <motion.div
          initial={{ x: 0, opacity: 0 }}
          whileInView={{ x: [-100, 0], opacity: 1 }}
          className="contact_left_container"
        >
          <p className="contact_text">We are the future of software</p>
          {contacts.map((contact) => {
            return (
              <div className="contact_left" key={contact.id}>
                <div className="icon">{contact.icon}</div>
                <p>{contact.infoText}</p>
              </div>
            );
          })}
        </motion.div>
        <motion.div
          initial={{ x: 0, opacity: 0 }}
          whileInView={{ x: [100, 0], opacity: 1 }}
          className="contact_right"
        >
          <h3>Send us a message</h3>
          <form ref={form} onSubmit={sendEmail}>
            <div className="row">
              <input
                type="text"
                name="user_name"
                placeholder="Name"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                type="email"
                name="user_email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className="row">
              <textarea
                name="message"
                placeholder="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>

            <button
              type="submit"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.3 }}
              className="btn1"
              disabled={isLoading ? true : false}
            >
              {isLoading ? (
                <CircularProgress
                  color="default"
                  size="sm"
                  aria-label="Loading..."
                />
              ) : (
                <>
                  Send
                  <FaPaperPlane className="ml-1" />
                </>
              )}
            </button>
            <br />

            <span className="text-yellow-500 text-center">
              {done &&
                "Thanks for Reaching out to us, we will get back to you "}
            </span>

            <span className="text-red-500 text-center text-sm">
              {errorMessage}
            </span>
          </form>
        </motion.div>
      </div>
    </div>
  );
};

export default Contact;
