import { FaPhoneAlt, FaMapMarkerAlt, FaPaperPlane, FaUser } from "react-icons/fa";


export const contacts = [ 
    {
      id: 1,
      icon:  <FaUser/>,
      infoText: "CodeKazi"
    },
    {
      // fa-envelope
      id: 2,
      icon: <FaMapMarkerAlt />,
      infoText: "Nairobi , Kenya"
    },
    {
      id: 3,
      icon: <FaPaperPlane />,
      infoText: "contact@codekazi.org"
    },
    {
      id: 4,
      icon: <FaPhoneAlt />,
      infoText: "+254795344966"
    }
  ]