import React from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Services from "./components/services/service";
import Portfolio from "./components/portfolio/Portfolio";
import Footer from "./components/footer/Footer";
import Contact from "./components/Contact/Contact";
import Home from "./components/Home/Home";
import About from "./components/About/About";
// import Team from "./components/Team/team";
import { Popsms } from "./components/Popsms/popsms";
import { Testimonials } from "./components/testimonials/Testimonials";
import { ScrollTop } from "./util/pagechange";

function App() {
  return (
    <>
      <ScrollTop />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Navbar />
              <Home />
              <About />
              <Services />
              <Portfolio />
              {/* <Team /> */}
              <Testimonials />
              <Contact />
            </>
          }
        />
        <Route path="/popsms" element={<Popsms />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
