import React from "react";
import "./home.css";
import developer from "../../assets/coding-removebg-preview.png";
import { BsArrowRight } from "react-icons/bs";

export default function Home() {
  return (
    <div id="home" className="container home md:mt-12 sm:mt-6">
      <div className="grid md:grid-cols-2 sm:grid-cols-1">
        <div className="col-span-1 home-content md:px-12 sm:px-2">
          <h1 className="first-header text-yellow-400 font-semibold text-4xl">
            The Leading Mobile/Web Design & Development Company
          </h1>
          <p className="leading-7 py-4">
            We specialize in developing a cohesive mobile and web presence that
            drives tangible returns on investment for your business. Our
            strategy involves increasing traffic to both your website and mobile
            applications by enhancing your online visibility. Subsequently, we
            focus on efficiently converting this increased traffic into valuable
            leads and sales for your company.
          </p>

          <button className="mt-4 bg-yellow-400 text-white font-semibold text-xl p-2 rounded hover:bg-yellow-500">
            <span className="flex items-center">
              <a className="font-light text-lg hover:text-white" href="#contact">
                Contact Us
              </a>
              <BsArrowRight style={{marginLeft: ".5rem"}} />
            </span>
          </button>
        </div>
        <div className="col-span-1 image-home">
          <img className="home-image" src={developer} alt="developer" />
        </div>
      </div>
    </div>
  );
}
