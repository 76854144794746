import React, { useState } from "react";
import "./portfolio.css";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Card, CardBody, CardFooter } from "@nextui-org/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const Portfolio = () => {
  const navigate = useNavigate();

  const [cards] = useState([
    {
      title: "PopSms - Bulk SMS",
      image:
        "https://res.cloudinary.com/dcaxpicsr/image/upload/v1708724356/bulksms_rtqb7f.jpg",
      text: "Experience seamless communication with our bulk SMS and email service.",
      live: "popsms",
    },
    {
      title: "M-Tumbler",
      image:
        "https://res.cloudinary.com/mwikali/image/upload/v1679041808/Mtumbler_gh88qs.png",
      text: "Instant Bonga Points to cash converter",
      live: "proprietary",
    },
    {
      title: "NextAuth.js Documentation",
      image:
        "https://res.cloudinary.com/mwikali/image/upload/v1679043378/nextauth_icotjb.webp",
      text: "Documentation on how to use NextAuth.js",
      live: "https://www.nextauth.org/",
    },
    {
      title: "Dr Floor",
      image:
        "https://res.cloudinary.com/mwikali/image/upload/v1679050963/dr_x4yqnh.avif",
      text: "Site that allows you to view and purchase flooring materials",
      live: "proprietary",
    },
  ]);

  return (
    <div
      id="portfolio"
      className="container relative portfolio md:-mt-16 sm:-mt-34"
    >
      <div className="py-2 text-center">
        <span className="text-yellow-500 uppercase">Portfolio</span>
        <h1 className="text-4xl font-semibold">Our Projects</h1>
      </div>

      <div className="flex flex-wrap justify-center">
        {cards.map((card) => (
          <Card key={card.title} className="py-1 m-2 md:w-2/5 sm:w-full shadow-md">
            <CardBody className=" py-2">
              <img
                alt="Card background"
                className="object-cover rounded-xl w-full h-48 "
                src={card.image}
              />

              <p className="pt-2 text-large uppercse font-semibold text-black-800">
                {card.title}
              </p>
              <small className="text-default-600">{card.text}</small>
            </CardBody>
            <CardFooter className="flex justify-end">
              {card.live === "popsms" ? (
                <Button
                  className="text-yellow-600 hover:text-blue-800"
                  variant="light"
                  onClick={() => {
                    navigate("/popsms");
                  }}
                >
                  View Site
                </Button>
              ) : (
                card.live === "proprietary"?(
                  <Button
                    className="text-yellow-600 hover:text-blue-800"
                    variant="light"
                    onClick={() => {
                      navigate("#portfolio");
                    }}
                  >
                    Proprietary
                  </Button>
                ):(
                  <a href={card.live}>
                  <Button
                    className="text-yellow-600 hover:text-blue-800"
                    variant="light"
                  >
                    View Site
                  </Button>
                </a>
                )
              )}
            </CardFooter>
          </Card>
        ))}
      </div>
    </div>
  );
};
export default Portfolio;
