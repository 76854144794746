import React, { useState } from "react";
import "./service.css";
import { Card, CardBody } from "@nextui-org/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { services } from "./Data";

const Services = () => {

  const [cards] = useState(services);

  return (
    <div
      id="services"
      className="container relative services md:-mt-16 sm:-mt-34"
    >
      <div className="py-2 text-center">
        <span className="text-yellow-500 uppercase">Services</span>
        <h1 className="text-4xl font-semibold">What We Do</h1>
      </div>

      <div className="flex flex-wrap justify-center">
        {cards.map((card) => (
          <Card key={card.title} className="py-1 m-2 md:w-2/5 sm:w-full shadow-md">
            <CardBody className=" py-2">
              <img
                alt="Card background"
                className="object-cover rounded-xl w-full h-48 "
                src={card.image}
              />

              <p className="pt-2 text-large uppercse font-semibold text-black-800">
                {card.title}
              </p>
              <small className="text-default-600">{card.text}</small>
            </CardBody>
          </Card>
        ))}
      </div>
    </div>
  );
};
export default Services;
