import React, { useEffect, useRef, useState } from "react";
import { FaArrowRightLong, FaArrowLeftLong } from "react-icons/fa6";
import "./Testimonials.css";

export const Testimonials = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const autoSlideInterval = 5000;
  let intervalRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleNextClick();
    }, autoSlideInterval);

    intervalRef.current = intervalId;

    return () => clearInterval(intervalRef.current);
  }, [currentSlide]);

  const handlePrevClick = () => {
    setCurrentSlide((prevIndex) =>
      prevIndex === 0 ? slides.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setCurrentSlide((prevIndex) =>
      prevIndex === slides.length - 1 ? 0 : prevIndex + 1
    );
  };

  const nextSlide = (index) => setCurrentSlide(index);

  return (
    <div className="container">
      <div className="py-2 text-center">
        <span className="text-yellow-500 uppercase">Testimonials</span>
        <h1 className="text-4xl font-semibold">What our customers say</h1>
      </div>
      <div className="slider-container">
        <div className="slides-items">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={index === currentSlide ? "slide active" : "slide"}
              style={{ transform: `translateX(-${currentSlide * 100}%)` }}
            >
              <div className="testimony-card">
                <div className="testimony-desc">
                  <h2 className="font-bold text-4xl text-yellow-400">"</h2>
                  <p>{slide.desc}</p>

                  <div className="testimony-img-wrapper">
                    <div>
                      <h5 className="text-yellow-600">{slide.name}</h5>
                      <h6>{slide.company}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="pagination-wrapper">
          <div className="pagination">
            {slides.map((slide, index) => (
              <button
                key={index}
                className={index === currentSlide ? "active" : ""}
                onClick={() => nextSlide(index)}
              >
                <span
                  className={index === currentSlide ? "pag show" : "pag"}
                ></span>
              </button>
            ))}
          </div>
        </div>

        <div className="nav">
          <button className="slider-prev" onClick={handlePrevClick}>
            <FaArrowLeftLong />
          </button>
          <button className="slider-next" onClick={handleNextClick}>
            <FaArrowRightLong />
          </button>
        </div>
      </div>
    </div>
  );
};

export const slides = [
  {
    desc: "We've been using CodeKazi's products for years now, and we couldn't be happier. Their software is robust, reliable, and continually updated with new features. The team behind it is responsive and truly cares about their customers' success.",
    name: "Dennis Rimbui",
    company: "",
  },
  {
    desc: "As a small business owner, finding affordable yet powerful software was crucial. CodeKazi not only met but exceeded our needs. Their user-friendly interface and customizable options have transformed the way we operate. Thank you for such an incredible product!",
    name: "Beverly Musone",
    company: "",
  },
  {
    desc: "Choosing CodeKazi was the best decision we made for our business. Their intuitive software streamlined our operations, saving us time and money. The seamless integration process and top-notch customer service exceeded our expectations!",
    name: "Mike Kibor",
    company: "",
  },
];
