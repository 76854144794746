import React from "react";
import "./About.css";

function About() {
  return (
    <div id="about" className="container about-c md:-mt-28 sm:-mt-12">
      <div className="grid md:grid-cols-2 sm:grid-cols-1">
        <div className="col-span-1 image-about m-4">
          <img
            className="object-cover p-8 about-image"
            src="https://res.cloudinary.com/dcaxpicsr/image/upload/v1713698937/carlos-muza-hpjSkU2UYSU-unsplash_jx4mn8.jpg"
            alt=""
          />
        </div>

        <div className="col-span-1 mx-1 about-content">
          <div className="py-2">
            <span className="text-yellow-500 uppercase">About Us</span>
            <h1 className="text-4xl font-semibold">Why Work with Us?</h1>
          </div>
          <br></br>
          <p className="p-content ">
            Partner with us to unlock the full potential of your digital
            presence. Our expertise lies in creating seamlessly integrated
            mobile and web solutions that guarantee a strong return on
            investment. By leveraging our proven strategies, we drive
            substantial traffic to your website and mobile applications,
            amplifying your online presence.
          </p>
          <br></br>
          <div className="flex items-center pb-3">
            <div className="circle-tick flex items-center justify-center">
              <span className="tick">✔</span>
            </div>
            <span className="ml-2">Trustworthy</span>
          </div>
          <div className="flex items-center pb-3">
            <div className="circle-tick flex items-center justify-center">
              <span className="tick">✔</span>
            </div>
            <span className="ml-2">Guaranteed Results</span>
          </div>
          <div className="flex items-center pb-3">
            <div className="circle-tick flex items-center justify-center">
              <span className="tick">✔</span>
            </div>
            <span className="ml-2 ">Fast and Reliable</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
